import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline"

export const Privacy = () => {
  React.useEffect(() => {
    if (window.location.hash) {
      // Fragment exists
      let elmnt = document.getElementById(window.location.hash.replace("#", ""))

      elmnt.scrollIntoView()
    } else {
      // Fragment doesn't exist
    }
  }, [])
  return (
    <Layout>
      <Headline title="Privacy Policy" excerpt=" " />

      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="alliance-india"
      >
        <div className="container content">
          <h3 className="is-size-5">ALLIANCE WORLD INDIA PRIVACY POLICY</h3>
          <p>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit
            www.allianceworldindia.com
          </p>
          <br />
          <h4>PERSONAL INFORMATION WE COLLECT</h4>
          <p>
            1. When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information.”
          </p>
          <p>
            2. We collect Device Information using the following technologies:{" "}
            <br />
            – “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org. <br />
            – “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps. <br />– “Web beacons,”
            “tags,” and “pixels” are electronic files used to record information
            about how you browse the Site.
          </p>
          <p>
            3. When we talk about “Personal Information” in this Privacy Policy,
            we are talking both about Device Information and Order Information.
          </p>

          <h4>HOW DO WE USE YOUR PERSONAL INFORMATION?</h4>
          <p>
            1. When in line with the preferences you have shared with us,
            provide you with information or advertising relating to our products
            or services.
          </p>
          <p>
            2. We also send promotional, transactional and commercial
            communications from Alliance World India through email or SMS.
          </p>
          <p>
            3. We use the Device Information that we collect to help us screen
            for potential risk and fraud (in particular, your IP address), and
            more generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </p>
          <h4>SHARING YOUR PERSONAL INFORMATION</h4>
          <p>
            1. We share your Personal Information with third parties to help us
            use your Personal Information, as described above. We also use
            Google Analytics to help us understand how our customers use the
            Site.
          </p>
          <p>
            2. Finally, we may also share your Personal Information to comply
            with applicable laws and regulations, to respond to a subpoena,
            search warrant or other lawful request for information we receive,
            or to otherwise protect our rights.
          </p>
          <h4>BEHAVIOURAL ADVERTISING</h4>
          <p>
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you.
          </p>
          <h4>DO NOT TRACK</h4>
          <p>
            Please note that we do not alter our Site’s data collection and use
            practices when we see a Do Not Track signal from your browser.
          </p>
          <h4>DATA RETENTION</h4>
          <p>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </p>
          <h4>CHANGES</h4>
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>
          <h4>CONTACT US</h4>
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at info@alliance-world.com or by mail using the details
            provided below:
          </p>
          <p>Alliance World India</p>
          <p>
            Head Office S.C.O. - 31, Sector 26, Madhya Marg, Chandigardh 160
            019, India
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
